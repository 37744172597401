export class FVCategory{
    public id : number = -1;
    public title : string = "";
    public photo : string = "";
    public status : number = 0;
    public is_selected = 0;

    constructor(id: number, title: string){
        this.id = id;
        this.title = title;
    }

    public static getCategories(){
        return [new FVCategory(1, "Musica"), new FVCategory(2, "Familia"), new FVCategory(5, "Deportes"), new FVCategory(14, "Arte/Teatro")];
    }
}