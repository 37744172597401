import { Component, OnInit } from '@angular/core';
import { CardService } from '../../services/card.service';

@Component({
  selector: 'app-reload',
  templateUrl: './reload.component.html',
  styleUrls: ['./reload.component.scss']
})
export class ReloadComponent implements OnInit {

  accountId = '';
  amount = 100;
  paymentId = 1;

  constructor(private cardService: CardService) { }

  ngOnInit() {
  }

  clickLoad() {
    if (this.accountId === '') {
      return alert('Ingrese su numero de Tarjeta');
    }
    this.cardService.generatePayment(178, this.accountId, this.amount, this.paymentId, data => {
      if (!data.success || data.response.url == '') {
        return alert('Hubo un problema, vuelva a intentarlo.');
      }

      window.location.href = data.response.url;
    });
  }
}
