import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutEliteModule } from '@mobileia/layout-elite';
import { BaseModule } from 'projects/base/src/public_api';
import { CoreModule } from '@mobileia/core';
import { AuthenticationModule } from '@mobileia/authentication';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { TopbarComponent } from './elements/topbar/topbar.component';
import { FooterComponent } from './elements/footer/footer.component';
import { ReloadComponent } from './pages/reload/reload.component';
import { ReturnComponent } from './pages/return/return.component';
import { AreiaComponent } from './pages/areia/areia.component';

const routes: Routes = [
  { path: '', redirectTo: '/inicio', pathMatch: 'full' },
  {
    path: 'inicio',
    component: AreiaComponent,
  },
  {
    path: 'retorno-pago',
    component: ReturnComponent,
  },
  {
    path: 'areia',
    component: AreiaComponent,
  },
];

@NgModule({
  declarations: [
    AppComponent,
    TopbarComponent,
    FooterComponent,
    ReloadComponent,
    ReturnComponent,
    AreiaComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    NgbModule.forRoot(),
    LayoutEliteModule,
    BaseModule,
    CoreModule,
    AuthenticationModule,
    RouterModule.forRoot(routes),
    SweetAlert2Module.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
