import { Component, OnInit, Input } from '@angular/core';
import { FVEvent } from '../../entity/event.entity';

@Component({
  selector: 'label-event-status',
  templateUrl: './event-status.component.html',
  styleUrls: ['./event-status.component.css']
})
export class EventStatusComponent implements OnInit {

  @Input() event : FVEvent;

  constructor() { }

  ngOnInit() {
  }

}
