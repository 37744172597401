import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { AuthenticationService, MIAUser } from '@mobileia/authentication';
import { Router } from '@angular/router';

@Component({
  selector: 'base-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TopbarComponent implements OnInit {

  @Input() logoIconDark : String = "";
  @Input() logoIcon : String = "";
  @Input() logoTextDark : String = "";
  @Input() logoText : String = "";

  currentUser : MIAUser = new MIAUser();

  constructor(
    private authService : AuthenticationService,
    private router: Router) { }

  ngOnInit() {
    // Buscar usuario logueado
    this.authService.getCurrentUser().subscribe(user => {
      this.currentUser = user;
    });
  }

  /**
   * logout
   */
  public logout() {
    this.authService.signOut();
    this.router.navigate(['/']);  
  }
}
