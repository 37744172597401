import { Injectable } from '@angular/core';
import { ApiResponse } from '@mobileia/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService, MiaAuthHttpService } from '@mobileia/authentication';
import { FVEvent } from '../entity/event.entity';
import { Constants } from '../constants';
import { FVFilter } from '../entity/filter.entity';
import { last } from '@angular/router/src/utils/collection';
import { MIAFilter } from '@mobileia/layout-elite';

@Injectable({
  providedIn: 'root'
})
export class FVEventService extends MiaAuthHttpService {

  private events = [];

  constructor(protected http: HttpClient, protected authService: AuthenticationService) {
    super(http, authService);
  }
  /**
   * Obtiene los eventos del usuario
   * @param page
   * @param where
   * @param search
   * @param filter
   * @param callback
   */
  public myEvents(page: number, where: string, search: string, filter: MIAFilter, callback: (data: any) => void) {
    this.requestAuthObject(Constants.BASE_URL_NEW + 'my-events',
    { filter: filter.title, asc: filter.asc, page: page, where: where, search: search }, callback);
  }
  /**
   * Obtiene todas las funciones de un evento
   * @param eventId ID del evento
   * @param callback Funcion que retorna los datos
   */
  public getPerformanes(eventId: number, callback: (data: [any]) => void) {
    this.requestAuthArray(Constants.BASE_URL_NEW + 'event/get-performances',
    { event_id: eventId }, callback);
  }
  /**
   * Obtiene las ventas de un evento
   * @param page
   * @param where
   * @param search
   * @param filter
   * @param callback
   */
  public getSales(eventId: number, page: number, where: string, search: string, filter: MIAFilter, callback: (data: any) => void) {
    this.requestAuthObject(Constants.BASE_URL_NEW + 'event/sales',
    { event_id: eventId, ord: filter.title, asc: filter.asc, page: page, where: where, search: search }, callback);
  }
  /**
   * Obtiene toda la información de un evento
   * @param eventId ID del evento
   * @param callback Funcion que retorna los datos
   */
  public fetchEvent(eventId: number, callback: (data: any) => void) {
    this.requestAuthObject(Constants.BASE_URL_NEW + 'event/get',
    { event_id: eventId }, callback);
  }
  /**
   * Servicio para cambiar el service Charge del evento
   * @param eventId ID del evento
   * @param serviceCharge % del service charge
   * @param callback Funcion que retorna la respuesta
   */
  public editServiceCharge(eventId: number, serviceCharge: string, callback: (data: boolean) => void) {
    this.requestAuthObject(Constants.BASE_URL_NEW + 'event/edit-service-charge', {
      event_id: eventId, tax_service: serviceCharge
    }, callback);
  }
  /**
   * Obtiene un evento atraves de su ID, verifica si ya esta cargado sino busca en el servidor
   * @param eventId 
   * @param callback 
   */
  public getEvent(eventId: number, callback: (event) => void) {
    let event = this.getEventById(eventId);
    if (event != null) {
      callback(event);
    } else {
      this.fetchEvent(eventId, ev => {
        this.events.push(ev);
        callback(ev);
      });
    }
  }

    /**
     * Devuelve todos los eventos asignado al usuario
     * @param callback
     */
  public fetchAll(filter: FVFilter, callback: (data: any) => void) {
    this.authService.getAccessToken().subscribe(token => {
        this.requestFetchAll(token, filter, callback);
    });
  }

  private requestFetchAll(access_token: string, filter: FVFilter, callback: (data: any) => void) {
    this.http.post<ApiResponse<[FVEvent]>>(Constants.BASE_URL_NEW + 'event/list', {access_token : access_token, filter: filter.title, asc: filter.asc}).subscribe(data => {
        if(data.success){
          // Guardamos eventos cargados en el servicio
          //this.events = data.response;
          // Llamamos al callback
            callback(data.response);
        }
    });
  }
  /**
     * Devuelve todos los usuarios de un evento
     * @param callback 
     */
    public fetchAllPermission(eventId : number, callback : (data: [any])=> void){
      this.authService.getAccessToken().subscribe(token => {
          this.requestFetchAllPermission(token, eventId, callback);
      });
    }
    private requestFetchAllPermission(access_token: string, eventId : number, callback : (data: [any])=> void){
      this.http.post<ApiResponse<[any]>>(Constants.BASE_URL_NEW + 'event/permissions', {access_token : access_token, event_id: eventId}).subscribe(data => {
          if(data.success){
            // Llamamos al callback
              callback(data.response);
          }
      });
    }
    /**
     * Servicio para generar un usuario con permisos en un evento
     * @param eventId 
     * @param firstname 
     * @param lastname 
     * @param email 
     * @param password 
     * @param role 
     * @param callback 
     */
    public addUserWithPermission(eventId : number, firstname : string, lastname : string, email : string, password : string, role : number, callback : (data: any) => void ){
      this.authService.getAccessToken().subscribe(token => {
        this.requestAddUserWithPermission(token, eventId, firstname, lastname, email, password, role, callback);
    });
    }

    private requestAddUserWithPermission(access_token: string, eventId : number, firstname : string, lastname : string, email : string, password : string, role : number, callback : (data: any) => void ){
      this.http.post<ApiResponse<[any]>>(Constants.BASE_URL_NEW + 'event/permission/add', {access_token : access_token, event_id: eventId, firstname : firstname, lastname: lastname, email: email, password: password, role: role}).subscribe(data => {
        if(data.success){
          // Llamamos al callback
            callback(data.response);
        }
    });
    }

    public editStatus(eventId: number, status: string, callback: (data: boolean) => void) {
      this.authService.getAccessToken().subscribe(token => {
          this.requestEditStatus(token, eventId, status, callback);
      });
    }

    private requestEditStatus(access_token: string, eventId: number, status: string, callback: (data: boolean) => void) {
      this.http.post<ApiResponse<boolean>>(Constants.BASE_URL_NEW + 'event/edit-status',
      { access_token: access_token, event_id: eventId, status: status }).subscribe(data => {
          if (data.success) {
              callback(true);
          } else {
            callback(false);
          }
      });
    }

  public getEventById(id : number) : FVEvent {
    for (let i = 0; i < this.events.length; i++) {
      const event = this.events[i];
      if(event.id == id){
        return event;
      }
    }
    return null;
  }
}
