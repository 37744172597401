import { Injectable } from '@angular/core';
import { MiaAuthHttpService, AuthenticationService } from '@mobileia/authentication';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '@mobileia/core';
import { Constants } from 'projects/base/src/lib/constants';

@Injectable({
  providedIn: 'root'
})
export class CardService extends MiaAuthHttpService {

  constructor(protected http: HttpClient, protected authService: AuthenticationService) {
    super(http, authService);
  }
  /**
   * Obtener el saldo de una tarjeta
   * @param storeId 
   * @param num 
   * @param callback 
   */
  public getBalance(storeId, num) {
    return this.postAuthObject(Constants.BASE_URL_NEW + 'rfid/get-balance', {
      store_id: storeId,
      card_num: num
    });
  }

  public generatePayment(storeId, num, amount, paymentId, callback: (data: any) => void) {
    this.http.post<ApiResponse<any>>(Constants.BASE_URL_NEW + 'load-money-rfid',
    {
      store_id: storeId,
      card_num: num,
      amount: amount,
      payment_id: paymentId
    }).subscribe(data => {
      callback(data);
    });
  }

  public generatePaymentWithoutCard(storeId, amount, paymentId, email: string, callback: (data: any) => void) {
    this.http.post<ApiResponse<any>>(Constants.BASE_URL_NEW + 'load-money-without-card-rfid',
    {
      store_id: storeId,
      email: email,
      amount: amount,
      payment_id: paymentId
    }).subscribe(data => {
      callback(data);
    });
  }
}
