import { Component, OnInit } from '@angular/core';
import { MIAUser, AuthenticationService } from '@mobileia/authentication';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  currentUser: MIAUser = new MIAUser();
  isMiniSidebar = false;

  constructor(private authService: AuthenticationService,
    private router: Router) { }

  ngOnInit() {
    this.currentUser.firstname = '';
    // Buscar usuario logueado
    this.authService.getCurrentUser().subscribe(user => {
      if (user == null) {
        return;
      }
      this.currentUser = user;
    });
  }

  public onResize(event) {
    if (event.target.innerWidth < 1170) {
      this.isMiniSidebar = true;
      // this.renderer.addClass(document.body, 'mini-sidebar');
    } else {
      this.isMiniSidebar = false;
      // this.renderer.removeClass(document.body, 'mini-sidebar');
    }
  }
}
