export class FVEvent{
    public static readonly VISIBILITY_PUBLIC = 0;
    public static readonly VISIBILITY_PRIVATE = 1;

    public static readonly TYPE_TICKET_DIGITAL = 0;
    public static readonly TYPE_TICKET_PHYSICAL = 1;

    public id : number;
    public title : string = "";
    public caption : string = "";
    public visibility : number = -1;
    public type_ticket : number = -1;
    public has_performances : number = -1;
    public place : string = ""; // Nombre del lugar
    public address : string = "";
    public latitude : number = -1;
    public longitude : number = -1;
    public photo : string = "";

    /* Old DB */
    
    public user_id : number;
    public store_id : number;
    public type : number;
    
    
    public video : string;
    public start_date : string;
    public status : number;

    public ticket_sell : number;
    public ticket_free : number;
    public ticket_total : number;
    
}