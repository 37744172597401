import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from './base.component';
import { AuthenticationModule } from '@mobileia/authentication'
import { environment } from 'src/environments/environment';
import { TopbarComponent } from './topbar/topbar.component';
import { LoadingComponent } from './loading/loading.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { EventStatusComponent } from './labels/event-status/event-status.component';
import { LayoutEliteModule } from '@mobileia/layout-elite';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule.forRoot(), 
    ReactiveFormsModule,
    AuthenticationModule.forRoot({ apiKey: environment.mobileiaLabAppId}),
    RouterModule,
    HttpClientModule,
    LayoutEliteModule
  ],
  declarations: [BaseComponent, TopbarComponent, LoadingComponent, FooterComponent, LoginComponent, EventStatusComponent],
  exports: [BaseComponent, TopbarComponent, FooterComponent, LoginComponent, EventStatusComponent]
})
export class BaseModule { }
