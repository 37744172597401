import { Component, OnInit, ViewChild } from '@angular/core';
import { CardService } from '../../services/card.service';
import { SwalComponent } from '@toverux/ngx-sweetalert2';

@Component({
  selector: 'app-areia',
  templateUrl: './areia.component.html',
  styleUrls: ['./areia.component.scss']
})
export class AreiaComponent implements OnInit {

  @ViewChild('balanceSwal') private balanceSwal: SwalComponent;

  accountId = '';
  amount = 250;
  paymentId = 1;
  storeId = 178; // Areia
  hasNotCard = false;
  email = '';

  constructor(private cardService: CardService) { }

  ngOnInit() {
  }

  clickGetBalance() {
    if (this.accountId === '') {
      return alert('Ingrese su numero de Tarjeta');
    }

    this.cardService.getBalance(this.storeId, this.accountId).subscribe(data => {
      if (!data.success) {
        return;
      }

      this.balanceSwal.title = 'Tu saldo es: $' + data.response.amount;
      this.balanceSwal.text = 'Tarjeta Nº ' + this.accountId;
      this.balanceSwal.show();
    });
  }

  clickLoad() {
    // verificar si todavia no tiene tarjeta
    if (this.hasNotCard) {
      return this.loadWithoutCard();
    }

    if (this.accountId === '') {
      return alert('Ingrese su numero de Tarjeta');
    }

    this.cardService.generatePayment(this.storeId, this.accountId, this.amount, this.paymentId, data => {
      if (!data.success || data.response.url == '') {
        return alert('Hubo un problema, vuelva a intentarlo.');
      }

      window.location.href = data.response.url;
    });
  }

  loadWithoutCard() {
    // Verificar si agrego el email
    if (this.email === '') {
      return alert('Debe ingresar un email');
    }

    this.cardService.generatePaymentWithoutCard(178, this.amount, this.paymentId, this.email, data => {
      if (!data.success || data.response.url == '') {
        return alert('Hubo un problema, vuelva a intentarlo.');
      }
      window.location.href = data.response.url;
      });
    }
}
