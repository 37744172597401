import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'card';
  isLoading = true;
  isLogged = false;

  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterContentInit() {
    // Terminar el loading
    this.isLoading = false;
  }
}
